import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";

// axios
import axios from "axios";

// types
import { IFQCN_BUI } from "@/utils/types/common";
import {
  redirect,
  usePathname,
  useRouter,
  useSearchParams,
} from "next/navigation";
import { useLocale } from "next-intl";
import CallAPI from "@/utils/functions/callAPI";

interface DetailedShowContextProps {
  data: any;
  loading: boolean;
  fetchData: () => void;
  fqcn_bui: IFQCN_BUI;
}

const DetailedShowContext = createContext<DetailedShowContextProps>({
  data: {},
  loading: true,
  fetchData: () => {},
  fqcn_bui: {
    Bundle: "",
    Unit: "",
    Interface: "",
  },
});

const DetailedShowContextProvider: React.FC<{
  children: ReactNode;
  resource: string;
  fqcn_bui: IFQCN_BUI;
}> = ({ children, resource, fqcn_bui }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  const locale = useLocale();

  const { push } = useRouter();
  const pathname = usePathname();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await CallAPI(
        "GET",
        resource,
        "",
        "",
        pathname?.split("/")[3]
      );

      setData(data);
    } catch (error) {
      console.error(error);
      push(`/${locale}/${resource}`);
    } finally {
      setLoading(false);
    }
  }, [resource]);

  useEffect(() => {
    if (initialLoad) {
      const query = new URLSearchParams(window.location.search);

      const filters: { [key: string]: any } = {};
      query.forEach((value, key) => {
        filters[key] = value;
      });

      setInitialLoad(false);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (!initialLoad) {
      fetchData();
    }
  }, [initialLoad, fetchData]);

  return (
    <DetailedShowContext.Provider
      value={{
        data,
        loading,
        fetchData,
        fqcn_bui,
      }}
    >
      {children}
    </DetailedShowContext.Provider>
  );
};

const useDetailedShowContext = () => useContext(DetailedShowContext);

export { DetailedShowContextProvider, useDetailedShowContext };

import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";

// Cookies
import cookies from "js-cookie";

/**
 * CallAPI
 * @param method - string
 * @param resource - string
 * @param filters - string
 * @param payload - object
 * @param id - string
 * @param domainURL - string
 *
 * @returns response
 */
export default async function CallAPI(
  method: string,
  resource: string,
  filters?: string,
  payload?: Object,
  id?: number | string,
  domainURL?: string
) {
  const instance = Axios.create();
  const axios = setupCache(instance, { storage: undefined });

  try {
    let response;
    if (method === "POST") {
      response = await axios.post("/api/callOut", payload, {
        cache: false,
        params: { resource, filters, domainURL },
      });
    } else if (method === "PUT") {
      response = await axios.put("/api/callOut", payload, {
        cache: false,
        params: { resource, filters, id },
      });
    } else if (method === "DELETE") {
      response = await axios.delete("/api/callOut", {
        cache: false,
        params: { resource, filters, id },
      });
    } else {
      response = await axios.get("/api/callOut", {
        params: {
          resource,
          filters,
          id,
        },
        cache: {
          ttl: 60 * 60 * 1000,
        },
      });
    }

    return response;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      cookies.remove("currentUser");
      cookies.remove("roles");
      window.location.reload();
    }

    throw err;
  }
}
